<template>
  <div ref="talkjsContainer" class="chat-container"></div>
</template>

<script>
import Talk from "talkjs";

export default {
  data() {
    return {
      TALK_APP_ID: "tjBGvoRX",

      COMPANY_NAME: this.$store.getters.company.nome
        .replace(/\s+/g, "")
        .toUpperCase(),

      LOGGED_USER_ID: this.$store.getters.loggedUser.id,
      LOGGED_USER_FULLNAME: (
        this.$store.getters.loggedUser.nome +
        " " +
        this.$store.getters.loggedUser.cognome
      )
        .replace(/\s+/g, "")
        .toUpperCase(),

      LOGGED_USER: this.$store.getters.loggedUser,

      session: null,

      conversation: null,
    };
  },
  mounted() {
    console.log("Mounted TalkJS", this.$store.getters);

    Talk.ready
      .then(() => {
        const me = new Talk.User({
          id: "Axerta_BO_43",
          name: this.LOGGED_USER_FULLNAME,
          email: this.LOGGED_USER.email,
          photoUrl: "https://example.com/user.jpg",
          welcomeMessage: "Ciao! Come posso aiutarti?",
        });

        const other = new Talk.User({
          id: this.COMPANY_NAME + "_BO_ALTROUTENTE_54321",
          name: "Altro Utente",
          email: "altro@example.com",
          photoUrl: "https://example.com/other.jpg",
          welcomeMessage: "Ciao! Sto aspettando il tuo messaggio.",
        });

        this.session = new Talk.Session({
          appId: this.TALK_APP_ID,
          me: me,
        });

        const conversation = this.session.getOrCreateConversation(
          Talk.oneOnOneId(me, other)
        );

        conversation.setParticipant(me);
        conversation.setParticipant(other);

        const inbox = this.session.createInbox({ selected: conversation });
        inbox.mount(this.$refs.talkjsContainer);

        // Ascolta l'evento dei messaggi personalizzati
        inbox.onCustomMessageAction("important", (event) => {
          const message = event.message;

          message.type = "important_text";

          if (message.custom.isImportant) {
            message.className = "important-message";
          }

          console.log("Messaggio importante:", {
            message: event,
          });
        });
      })
      .catch((error) => {
        console.error("Errore nell'inizializzazione di TalkJS:", error);
      });
  },

  beforeDestroy() {
    if (this.session) {
      this.session.destroy();
    }
  },
};
</script>

<style scoped>
.chat-container {
  height: 500px;
  width: 100%;
}

[data-important="true"] {
  background-color: yellow; /* Cambia colore come desiderato */
  border-left: 5px solid red;
}

.important-message {
  background-color: #eec4092c; /* Colore di evidenziazione */
  border-left: 5px solid #eec409; /* Aggiungi un bordo per maggiore visibilità */
}
</style>
