<template>
  <div class="content-wrapper">
    <h1>Testing Page</h1>

    <TalkJs />
  </div>
</template>

<script>
import TalkJs from "@/components/chat/TalkJs.vue";
export default {
  name: "TestTry",
  components: {
    TalkJs,
  },
};
</script>
